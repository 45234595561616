
export default function Image(props) {
    if (props.image) {
        return (
            <img src={props.image} className={props.className} alt={props.text} />
        )
    }
    return (
        <div className={props.className}>
            {props.text}
        </div>
    )
}