import p1 from "../assets/p1.png";

const style = {
    background: 'linear-gradient(228deg, rgba(255,255,255,1) 0%, rgba(0,115,12,1) 100%)'
}

export default function Hero1() {
    return (
        <div style={style} className="relative pt-2 py-3 px-4">
            <div className="flex">
            <span className="text-[#212529] mt-2">
                <img src={p1} alt="" />
            </span>
            <span className="m-0 pt-3 pl-1 font-nunito text-[#212529] font-bold text-[14px]">Haus und Garten Service</span>
            </div>
            <p className="text-[28px] mt-3 font-nunito font-bold text-center leading-7">
                Perfekte Lösung für ihren <span className="text-main-green">Garten.</span>
            </p>

            <p className="text-center mt-4 text-sm font-nunito font font-bold">
            Wir sorgen dafür, dass Ihr Garten immer in einem optimalen Zustand ist, indem wir ihn regelmäßig pflegen und säubern.
            </p>

            <br />
            <p className="text-center text-xl font-nunito font-bold mt-2">Zufriedene Kunden</p>
            <p className="text-center text-5xl font-nunito font-bold mt-2">100+</p>

            <br />
            <p className="text-center text-sm font-nunito font-bold mt-2">Kontaktieren Sie uns noch heute, um eine kostenlose Konsultation zu vereinbaren!</p>
        </div>
    )
}