import Header from "./components/Header";
import Image from "./components/Image";
import MainGardening from "./assets/main.jpg";
import Hero1 from "./components/Hero1";
import Services from "./components/Services";
import ContactUs from "./components/ContactUs";

import "./App.css"

export default function App() {
  return (
    <main id="main">
      <div id="header">
        <Header></Header>
      </div>
      <div id="cover">
        {/* <Image width="375" height="240" text="375 x 240" image="" className="w-full h-96 bg-[#c4c4c4] text-4xl flex flex-row justify-center align-middle self-center items-center font-semibold" /> */}
        <Image width="375" height="240" text="Gardening" image={MainGardening} />
      </div>
      <div id="hero">
        <Hero1 />
      </div>
      <div id="services">
        <Services />
      </div>
      <div id="contact">
        <ContactUs />
        </div>
    </main>
  )
}