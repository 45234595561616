import { Link } from "react-router-dom";

export default function Services() {
    const services_provide = [
        {
            name: '',
            title: 'Hochdruckreinigung',
            image: 'assets/pressure-washing.webp',
            description: `Unsere professionelle Reinigungsdienstleistung ist darauf spezialisiert, Terrassen, Auffahrten, Wege und andere Stein- und Betonflächen wieder zum Strahlen zu bringen. Mit unserem erfahrenen Team und hochmodernen Reinigungstechniken sorgen wir für eine gründliche und effektive Reinigung, die Ihren Außenbereich in neuem Glanz erstrahlen lässt.

            Unser Service umfasst die Entfernung von Schmutz, Staub, Algen, Moos, Flechten und anderen Verunreinigungen, die sich im Laufe der Zeit auf den Oberflächen ansammeln. Wir setzen dabei umweltfreundliche Reinigungsmittel und professionelle Geräte ein, um die bestmöglichen Ergebnisse zu erzielen, während wir gleichzeitig die Umwelt schonen.
            
            Unser geschultes Team geht sorgfältig vor, um Beschädigungen an den Oberflächen zu vermeiden und sicherzustellen, dass jede Ecke gründlich gereinigt wird. Wir passen unsere Reinigungsmethoden und den Wasserdruck an die spezifischen Anforderungen der verschiedenen Oberflächen an, um beste Ergebnisse zu erzielen.
            
            Ob es sich um eine private Terrasse, eine gewerbliche Auffahrt oder einen öffentlichen Gehweg handelt, wir kümmern uns um jede Art von Stein- und Betonfläche. Unsere Dienstleistung hilft nicht nur, die Ästhetik und den Wert Ihrer Immobilie zu erhalten, sondern auch die Sicherheit zu gewährleisten, indem rutschige Beläge und Verunreinigungen entfernt werden.`,
            url: "/gallery",
            images: [
                "assets/images/0E853625-0A27-44B7-84B4-3E0DB1C6D278.jpg",
                "assets/images/7BFADC89-41F1-43CA-A60B-C5A1FC1A2F48.jpg",
                "assets/images/7E4767C9-086F-45FF-8616-077E382A3D50.jpg",
                "assets/images/9D9E9D0F-F324-4D08-9011-942EC63FE7BE.jpg",
                "assets/images/020c8b1d-a2da-4768-8b74-4645143c9227.jpg",
                "assets/images/60A1DB86-56E5-490C-A5A5-503DB468B88F.jpg",
                "assets/images/67FB6973-B0B6-4E40-9C4D-E4B7BC1E854B.jpg",

                "assets/images/228a0e16-7658-4103-ad6c-4318ff894a8c.jpg",
                "assets/images/2245ed84-24cf-4ac8-8038-66409e03b29a.jpg",
                "assets/images/6877b43a-3048-49aa-a162-688ff334355b.jpg",
                "assets/images/34137c1f-4738-4017-b8bf-71bc671c0f37.jpg",
                "assets/images/36445be0-3be3-469d-9087-b3e4d2c017c3.jpg",
                "assets/images/0704480A-0AEC-43B5-A176-0059327030E8.jpg",
                "assets/images/BA1B94B7-CAD0-4095-93B4-8C4F0470F818.jpg",

                "assets/images/e4d1a30b-d409-4f44-b6c1-245a9f523d8f.jpg",
                "assets/images/e35f48ae-141c-47c2-b9c6-95befdde94eb.jpg",
                "assets/images/f7f11f14-d5ea-4b9a-9fb8-f4333e2fb05c.jpg",
                "assets/images/F20ED688-E61F-4761-A34B-6E9C62FB4C3A.jpg",
                "assets/images/IMG_1891.JPG",
                "assets/images/IMG_6632.JPG",
                "assets/images/IMG_6909.JPG",
                "assets/images/IMG_7367.JPG",
                "assets/images/IMG_6948.PNG",
            ]
        },
        {
            name: '',
            title: 'Heckendschneiden',
            image: 'assets/Heckendschneiden.jpeg',
            description: `Unser professioneller Heckenschnitt-Service bietet Ihnen eine fachgerechte Pflege und Gestaltung Ihrer Hecken. Mit unserem erfahrenen Team und unseren spezialisierten Werkzeugen sorgen wir für eine präzise und ästhetisch ansprechende Pflege Ihrer Grünflächen.

            Wir verstehen die Bedeutung einer gut gepflegten Hecke, sei es für den visuellen Reiz, die Privatsphäre oder die Strukturierung Ihres Gartens. Unser Team arbeitet eng mit Ihnen zusammen, um Ihre individuellen Vorlieben und Anforderungen zu verstehen und umzusetzen.
            
            Unser Service umfasst das Schneiden, Formen und Ausdünnen von Hecken aller Art. Wir verwenden professionelle Werkzeuge und Techniken, um einen gleichmäßigen und sauberen Schnitt zu gewährleisten. Dabei achten wir darauf, dass die natürliche Gesundheit und das Wachstum der Pflanzen erhalten bleiben.
            
            Unsere Mitarbeiter sind erfahren und sorgfältig geschult, um die Arbeit effizient und präzise durchzuführen. Wir kümmern uns um alle Aspekte des Heckenschnitts, einschließlich der Entsorgung des Schnittguts, sodass Sie sich um nichts kümmern müssen.
            
            Unabhängig von der Größe oder Art Ihrer Hecke bieten wir maßgeschneiderte Lösungen, die auf Ihre Bedürfnisse zugeschnitten sind. Wir arbeiten mit Sorgfalt und Präzision, um sicherzustellen, dass Ihre Hecke gesund, gut geformt und optisch ansprechend ist.`,
            url: "/gallery",
            images: [
                "assets/images/18F74CEA-6C88-49AF-8F00-77DC39463B07.jpg",
                "assets/images/879E3C90-CBE9-4B46-8A28-A07543F3BF2A.jpg",
                "assets/images/7815B40E-1516-46C5-9E38-D41C223CD077.jpg",
                "assets/images/9606BDAE-6A6F-468E-9C33-5632D6FCA104.jpg",
                "assets/images/60682DEF-5D26-4F89-9C39-6F300A13DDF7.jpg",
                "assets/images/IMG_1357.JPG",
                "assets/images/IMG_1856.PNG",
                "assets/images/IMG_5384.JPG",
                "assets/images/IMG_5566.JPG",
                "assets/images/IMG_7418.JPG",
                "assets/images/IMG_6704.PNG",
            ]
        },
        {
            name: '',
            title: 'Dachrinnenreinigung',
            image: 'assets/Dachrinnenreinigung.jpg',
            description: `Unser professioneller Service zur Dachrinnenreinigung bietet Ihnen eine gründliche und effektive Reinigung Ihrer Dachrinnen. Mit unserem spezialisierten Team und unseren professionellen Werkzeugen sorgen wir dafür, dass Ihre Dachrinnen frei von Schmutz, Blättern, Verstopfungen und anderen Ablagerungen sind.

            Gut funktionierende Dachrinnen sind von entscheidender Bedeutung, um das Regenwasser ordnungsgemäß abzuleiten und Schäden an Ihrem Haus oder Ihrer Immobilie zu vermeiden. Unsere erfahrenen Mitarbeiter gehen sorgfältig vor, um Verstopfungen zu beseitigen und sicherzustellen, dass das Wasser frei fließen kann.
            
            Unser Service umfasst die Entfernung von Blättern, Zweigen, Schmutz und anderen Ablagerungen, die sich im Laufe der Zeit in den Dachrinnen ansammeln. Wir verwenden geeignete Werkzeuge und Techniken, um sicher und effizient zu arbeiten, während wir gleichzeitig die Integrität Ihrer Dachrinnen wahren.
            
            Wir sind uns bewusst, dass jedes Gebäude und jede Dachrinne unterschiedliche Anforderungen hat. Daher passen wir unseren Service an die spezifischen Bedürfnisse Ihrer Immobilie an. Unser geschultes Team arbeitet mit Sorgfalt und Präzision, um sicherzustellen, dass Ihre Dachrinnen sauber und funktionsfähig sind.
            
            Wir empfehlen, die Dachrinnen regelmäßig zu reinigen, um potenzielle Probleme frühzeitig zu erkennen und zu vermeiden. Unsere Dienstleistung bietet Ihnen die Gewissheit, dass Ihre Dachrinnen in optimaler Verfassung sind und Ihr Haus vor möglichen Schäden durch überlaufendes Wasser geschützt ist.`,
            url: "/gallery",
            images: [
            ]
        },
        {
            name: '',
            title: 'Rasenmähen',
            image: 'assets/rasenmahen.jpg',
            description: `Unser professioneller Rasenmähservice bietet Ihnen eine fachgerechte und zuverlässige Pflege Ihres Rasens. Mit unserem erfahrenen Team und unseren hochwertigen Rasenmähern sorgen wir dafür, dass Ihr Rasen immer gepflegt und ansprechend aussieht.

            Ein sorgfältig gemähter Rasen ist nicht nur ästhetisch ansprechend, sondern fördert auch das gesunde Wachstum des Grüns. Unser Team verwendet präzise Einstellungen und Mähmethoden, um einen gleichmäßigen Schnitt und eine optimale Rasenhöhe zu gewährleisten.
            
            Unser Service umfasst das regelmäßige Mähen des Rasens, um das Wachstum zu kontrollieren und die Länge auf einem gewünschten Niveau zu halten. Wir verwenden hochwertige Rasenmäher, die mit scharfen Klingen ausgestattet sind, um einen sauberen und präzisen Schnitt zu erzielen.
            
            Unsere Mitarbeiter sind erfahren und sorgfältig geschult, um den Rasenmähvorgang effizient und schonend für Ihren Rasen durchzuführen. Wir achten darauf, dass keine Streifen oder ungleichmäßige Stellen entstehen und kümmern uns um die Entsorgung des Schnittguts.
            
            Wir bieten maßgeschneiderte Lösungen, die auf die Bedürfnisse Ihres Rasens zugeschnitten sind. Ob es sich um einen privaten Garten, eine öffentliche Grünfläche oder einen gewerblichen Standort handelt, wir passen unseren Service an die Größe und die spezifischen Anforderungen an.`,
            url: "/gallery",
            images: [

            ]
        },
        {
            name: '',
            title: 'Unkraut jäten',
            image: 'assets/Unkraut jaeten.webp',
            description: `Unser professioneller Unkrautjät-Service bietet Ihnen eine gründliche und effektive Entfernung von Unkraut aus Ihren Gärten, Beeten und anderen Außenbereichen. Mit unserem erfahrenen Team und unserem Fachwissen sorgen wir dafür, dass Ihre Grünflächen frei von unerwünschtem Unkraut sind.

            Unkraut kann nicht nur das Erscheinungsbild Ihres Gartens beeinträchtigen, sondern auch um wertvolle Nährstoffe konkurrieren und das Wachstum Ihrer Pflanzen behindern. Unsere geschulten Mitarbeiter gehen sorgfältig vor, um das Unkraut auf effiziente Weise zu entfernen, während sie die Gesundheit Ihrer Pflanzen und Blumen berücksichtigen.
            
            Unser Service umfasst das Jäten von Unkraut per Hand oder mit geeigneten Werkzeugen, um sicherzustellen, dass die Wurzeln gründlich entfernt werden. Wir erkennen verschiedene Arten von Unkraut und verwenden entsprechende Techniken, um eine nachhaltige Unkrautbekämpfung zu gewährleisten.
            
            Unser Team arbeitet präzise und konzentriert, um sicherzustellen, dass keine Schäden an Ihren Pflanzen oder Beeten verursacht werden. Wir entfernen das Unkraut von den Wurzeln an und entsorgen es ordnungsgemäß, um eine erneute Ausbreitung zu verhindern.
            
            Wir bieten maßgeschneiderte Lösungen, die auf die spezifischen Anforderungen Ihrer Grünflächen zugeschnitten sind. Unabhängig von der Größe oder Art Ihres Gartens kümmern wir uns um das Unkrautproblem und hinterlassen Ihnen einen gepflegten und sauberen Außenbereich.`,
            url: "/gallery",
            images: [
                "assets/images/IMG_2320.JPG",
            ]
        },
        {
            name: '',
            title: 'Rollrasen verlegen',
            image: 'assets/Rollrasen verlegen.jpeg',
            description: `Unser professioneller Service für das Verlegen von Rollrasen bietet Ihnen eine schnelle und effiziente Lösung, um Ihren Garten mit einem sofortigen Grün zu verschönern. Mit unserem erfahrenen Team und unserer Fachkompetenz sorgen wir dafür, dass Ihr Rollrasen fachgerecht verlegt wird, um eine langlebige und attraktive Rasenfläche zu schaffen.

            Rollrasen ist eine praktische und zeitsparende Option, um Ihren Garten schnell in ein grünes Paradies zu verwandeln. Unser Team geht sorgfältig vor und beachtet dabei die Bodenbeschaffenheit, die Ausrichtung des Rasens und andere Faktoren, um optimale Wachstumsbedingungen zu gewährleisten.
            
            Unser Service umfasst die Vorbereitung des Bodens, einschließlich der Entfernung von Unkraut, Steinen und anderen Hindernissen. Wir begradigen und ebnen den Boden, um eine gleichmäßige Oberfläche zu schaffen, auf der der Rollrasen problemlos verlegt werden kann.
            
            Wir verwenden hochwertigen Rollrasen, der sorgfältig ausgewählt wurde, um eine hohe Qualität und eine gute Wurzelbildung zu gewährleisten. Unser Team verlegt den Rollrasen mit Präzision und kümmert sich um die richtige Ausrichtung und Nahtverbindung, um ein gleichmäßiges und ästhetisches Erscheinungsbild zu erzielen.
            
            Wir bieten Ihnen auch Beratung und Anleitungen zur richtigen Pflege und Bewässerung des neuen Rollrasens, um ein gesundes und kräftiges Wachstum zu fördern. Wir stehen Ihnen mit Rat und Tat zur Seite, damit Ihr neuer Rasen optimal gedeiht.`,
            url: "/gallery",
            images: [

            ]
        },
        {
            name: '',
            title: 'Grünschnitt Entsorgen',
            image: 'assets/gruenschnitt-entsorgen-kosten.jpg',
            description: `Unser professioneller Service für die Grünschnittentsorgung bietet Ihnen eine bequeme und umweltfreundliche Lösung, um Ihren Garten von Schnittabfällen und Grünschnitt zu befreien. Mit unserem erfahrenen Team und unseren spezialisierten Entsorgungsmethoden sorgen wir dafür, dass Ihr Grünschnitt ordnungsgemäß und verantwortungsvoll entsorgt wird.

            Das Entfernen von Grünschnitt kann eine zeitaufwändige Aufgabe sein, insbesondere wenn es sich um große Mengen von Ästen, Zweigen, Laub und anderen Gartenabfällen handelt. Unser Team kümmert sich um die fachgerechte Entsorgung, sodass Sie sich keine Sorgen machen müssen.
            
            Unser Service umfasst das Sammeln und Abtransportieren des Grünschnitts von Ihrem Grundstück. Wir verwenden geeignete Behälter und Fahrzeuge, um die Abfälle sicher zu transportieren und eine ordnungsgemäße Entsorgung gemäß den örtlichen Vorschriften und Umweltstandards zu gewährleisten.
            
            Wir achten darauf, dass der Grünschnitt umweltfreundlich entsorgt wird, sei es durch Kompostierung oder Recycling. Wir bemühen uns, den Einfluss auf die Umwelt zu minimieren und nachhaltige Praktiken zu fördern.
            
            Unser Team arbeitet effizient und zuverlässig, um Ihren Garten schnell von Grünschnitt zu befreien. Wir hinterlassen Ihren Außenbereich sauber und aufgeräumt, sodass Sie die Ergebnisse unserer Arbeit sofort genießen können.`,
            url: "/gallery",
            images: [
                "assets/images/IMG_1841.JPG",
                "assets/images/0704480A-0AEC-43B5-A176-0059327030E8.jpg",
                "assets/images/IMG_3584.JPG",
            ]
        },
        {
            name: '',
            title: 'Und Vieles mehr ...',
            description: `Unser professioneller Service bietet Ihnen vielseitige Dienstleistungen für verschiedene Anforderungen und Bedürfnisse. Mit unserem erfahrenen und gut ausgebildeten Team stehen wir Ihnen zur Verfügung, um Ihnen bei verschiedenen Aufgaben und Projekten behilflich zu sein.

            Unser breites Leistungsspektrum umfasst eine Vielzahl von Serviceleistungen, darunter Reparaturen, Montagearbeiten, Wartung, Reinigung, Umzugshilfe und vieles mehr. Unser Team verfügt über das Fachwissen und die Erfahrung, um Ihnen qualitativ hochwertige Lösungen zu bieten.
            
            Wir verstehen, dass jeder Kunde individuelle Anforderungen hat, daher bieten wir maßgeschneiderte Lösungen, die auf Ihre spezifischen Bedürfnisse zugeschnitten sind. Unser Ziel ist es, Ihnen den bestmöglichen Service zu bieten und Ihre Zufriedenheit sicherzustellen.
            
            Unsere Mitarbeiter sind professionell, zuverlässig und pünktlich. Wir legen großen Wert auf hohe Qualitätsstandards und gehen sorgfältig und gewissenhaft bei der Durchführung unserer Aufgaben vor. Sie können sich darauf verlassen, dass Ihre Anliegen in guten Händen sind.
            
            Wir sind bestrebt, Ihre Erwartungen zu übertreffen und einen exzellenten Kundenservice zu bieten. Unser freundliches und engagiertes Team steht Ihnen zur Verfügung, um Ihre Fragen zu beantworten und Ihnen professionelle Unterstützung zu bieten.`,
            image: 'assets/garden.jpg'
        }
    ];

    return (
        <div className="flex justify-center flex-wrap flex-row">
            <div className="p-3 pt-7">
                <p className="font-nunito font-bold text-2xl leading-7 flex justify-center text-center">
                    Dienstleistungen, die wir anbieten</p>

                <p className="text-center mt-4 text-sm">Von der Planung über den Bau und die Umgestaltung bis zur regelmäßigen Pflege bieten wir Ihnen ein umfassendes Spektrum an Leistungen für Ihren grünen Traum. Wir verstehen uns nicht nur als Gärtner, sondern auch als Handwerker und setzen Arbeiten aus beiden Bereichen mit hoher Kompetenz um. So können wir Ihnen eine ganzheitliche Betreuung aus einer Hand bieten, die Ihren Garten oder Außenbereich in jeder Hinsicht perfektioniert.</p>
                <br />
                <br />
                <div className="card flex flex-row justify-center flex-wrap gap-4">
                    {services_provide.map((s, i) => {
                        return (
                            <div className="shadow-2xl service-item">
                                <Link key={i} to={s.url} state={{ images: s.images, title: s.title, description: s.description }}>
                                    <div>
                                        <div className="flex flex-row justify-center text-center">
                                            <p className="font-bold font-nunito text-xl p-3">{s.title}</p>
                                        </div>
                                        {s.image && (<img key={i} className="mx-auto rounded block min-h-[210px] w-full h-full object-fill" src={require(`../${s.image}`)} alt="" />)}
                                    </div>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}