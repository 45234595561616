import { Link } from "react-router-dom";
import Logo from "../assets/leaves.png";

export default function Header() {
    return (
        <header>
            <div className="flex flex-row justify-between align-middle items-center">
                <Link to="/">
                    <div className="mx-4 my-5">
                        <div className="flex align-middle text-center">
                            <img className="w-8" src={Logo} alt="Logo" />
                            <span className="text-main-green font-bold mt-0 ml-2 text-2xl">Liri</span>
                        </div>
                    </div>
                </Link>
                <div className="hamburger-menu w-8 h-5 mx-5 my-5">
                    <div className="border-t-4 border-t-main-green max-w-full h-2"></div>
                    <div className="border-t-4 border-t-main-green max-w-full h-2"></div>
                    <div className="border-t-4 border-t-main-green max-w-full h-2"></div>
                </div>
            </div>
        </header>
    )
}