import React from "react";
import { Gallery, Item } from 'react-photoswipe-gallery'
import 'photoswipe/dist/photoswipe.css'

import { useLocation } from "react-router-dom";
import Header from "./Header";

export default function ImageGallery(props) {
  let { state } = useLocation();
  debugger;

  const smallItemStyles = {
    cursor: 'pointer',
    objectFit: 'cover',
    width: '100%',
    maxHeight: '100%',
  }

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="m-3">
      <Header></Header>
      <p className="font-nunito font-bold text-2xl leading-7 flex justify-center text-center mb-2 mt-4">
        {state.title}</p>

      <p className="text-center text-sm p-3">{state.description}</p>

      <Gallery>
        <div
          className="my-4"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
            justifyItems: 'center',
            gridGap: 12,
          }}
        >
          {
            state.images.map((image, index) => (
              <div>
                <Item
                  key={index}
                  original={require(`../${image}`)}
                  thumbnail={require(`../${image}`)}
                  width="1024"
                  height="768"
                >
                  {({ ref, open }) => (
                    <img style={smallItemStyles} ref={ref} onClick={open} src={require(`../${image}`)} alt="" />
                  )}
                </Item>
              </div>
            ))
          }
        </div>
      </Gallery>
    </div>
  );
}

